import React from "react";
import NavbarContent from "../../Navbar/Navbar";
import { Form, Input } from "antd";
import "../ApplicationFirst.css";

const ApplicationForm = () => {
  //   const formLayout = {
  //     labelCol: { span: 24 }, // Corrected
  //     wrapperCol: { span: 8 }, // Corrected
  //   };
  //   const itemNameStyle = {
  //     fontWeight: "bold",
  //     fontSize: "18px",
  //     color: "blue",
  //   };
  return (
    <>
      {/* <div className="applictn-formNav">
        <NavbarContent />
      </div>
      <div className="applictn-formMain">
        <div className="applictn-formContainer">
          <Form>
            <Form.Item
              {...formLayout}
              name={"application_name"}
              label={"APPLICATION NAME"}
              style={itemNameStyle}
            >
              <Input />
            </Form.Item>
          </Form>
        </div>
      </div> */}
    </>
  );
};

export default ApplicationForm;
