import React, { useEffect, useState } from "react";
import "./GalleryFirst.css";
import NavbarContent from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import { Row, Col } from "react-bootstrap";
import axios from "axios";

function GalleryFirst() {
  const [relation, setRelation] = useState([]);
  const [medicalBank, setMedicalBank] = useState([]);
  console.log(medicalBank);

  useEffect(() => {
    loadData();
  }, []);
  //   const loadData = async () => {
  //     let uri =
  //       "https://amityforum-cms-17e0731b42ce.herokuapp.com/api/public-relations";
  //     ("https://amityforum-cms-17e0731b42ce.herokuapp.com/api/api/medical-banks");
  //     await axios.get(uri).then((response) => {
  //       console.log(response);
  //       setData(response.data.data);
  //     });
  //   };
  const loadData = async () => {
    const apiUrl1 =
      "https://amityforum-cms-17e0731b42ce.herokuapp.com/api/public-relations";
    const apiUrl2 =
      "https://amityforum-cms-17e0731b42ce.herokuapp.com/api/medical-banks";

    try {
      const response1 = await axios.get(apiUrl1);
      console.log(response1.data);
      setRelation(response1.data.data);

      const response2 = await axios.get(apiUrl2);
      console.log("==========", response2.data);
      setMedicalBank(response2.data.data);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <>
      <div className="GalleryFirstStart">
        <NavbarContent />
        <div className="FirstPartOfGallery">
          <div className="SubheadingOfGallery">Home Cause</div>
          <div className="HeadingOfGallery">Amity Gallery</div>
        </div>
      </div>
      <div className="gallery-main">
        <div className="HeaderOfPublicDiv">Public relation</div>
        <div className="SecondStartOfGallery">
          {relation.map((item) => {
            console.log(item);
            return (
              <div>
                <div className="gallery_card">
                  <div className="galley-main">
                    <img
                      className="gallery_img"
                      src={item.attributes.image}
                      alt=""
                    />
                  </div>
                  <div className="gallery_txt">
                    {item.attributes.title.slice(0, 100)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {/*  */}
        <div className="HeaderOfPublicDiv">Amity Medical Bank</div>
        <div className="SecondStartOfGallery">
          {medicalBank.map((item) => {
            console.log(item);
            return (
              <div>
                <div className="gallery_card">
                  <div className="galley-main">
                    <img
                      className="gallery_img"
                      src={item.attributes.image}
                      alt=""
                    />
                  </div>
                  <div className="gallery_txt">
                    {item.attributes.title.slice(0, 100)}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </>
  );
}

export default GalleryFirst;