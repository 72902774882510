import React from "react";
import AWS from "aws-sdk";

const s3 = new AWS.S3({
  accessKeyId: "AKIAYCY7LW3IVD3BX3WJ",
  secretAccessKey: "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",
  region: "ap-south-1",
});

export const uploadImageToS3 = (fileVal, fileName) => {
  const params = {
    Bucket: "amity-bucket",
    Key: fileName,
    Body: fileVal,
  };
  return s3.upload(params).promise();
};
export const multipleImagesToS3 = (fileVal, fileName) => {
  const params = {
    Bucket: "amity-bucket",
    Key: fileName,
    Body: fileVal,
  };
  return s3.upload(params).promise();
};
