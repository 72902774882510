import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import './NavbarModel.css'
import close from '../Assets/Images/upper.png'
import { Link } from "react-router-dom";





function NavBarModel(props) {
  return (
    <>
      <Modal
        show={props.visible}
        fullscreen={true}
        onHide={() => props.onCancel()}
        className="modell"
      >
        <Modal.Body className="modell">
            
          <div className="selection">
            <Link to='/' className=" active2">
            <div className="home-div1">
              Home
            </div>
            </Link>
            <Link to="/Projects" className=" active2">
            <div className="projects-div1">
              Projects
            </div>
            </Link>
            <Link to='/Applications' className=" active2">
            <div className="application-div1">
              Applications
            </div>
            </Link>
            <Link to='/Gallery' className="active2">
            <div className="gallery-div1">
              Gallery
            </div>
            </Link>
            <Link to='/Report' className="active2">
            <div className="gallery-div1">
              Report
            </div>
            </Link>
            <Link to='/Contacts' className=" active2">
            <div className="contact-div1">
              Contact
            </div>
            </Link>
            {/* <Link to='/About' className=" active2" >
            <div className="about-div1">
              About
            </div>
            </Link> */}
          </div>
          
          <div className="close" onClick={() => props.onCancel()}><img src={close}/></div>

        </Modal.Body>
      </Modal>
    </>
  );
}

export default NavBarModel;
