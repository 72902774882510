import React, { useEffect, useState } from 'react';
import NavbarContent from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import './ReportFirst.css';
import axios from 'axios';

function ReportFirst() {
  const [data, setData] = useState([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    let url = 'https://amityforum-cms-17e0731b42ce.herokuapp.com/api/reports';
    await axios.get(url).then((response) => {
      console.log(response);
      setData(response.data.data);
    });
  };

  return (
    <>
      <div className='StartReport'>
        <NavbarContent />
        <div className='ProjectsTextsAndHeader'>
          <div className='TextOfProjects'>Home Causes</div>
          <div className='HeaderOfPojects'>Amity Report</div>
        </div>
      </div>
      <div className='gallery-main'> 
      <div className='SecondStartOfGallery'>
        {data.map((item) => {
          console.log(item);
          return (
            <div key={item.id}> {/* Added key attribute for mapping */}
              <div className='gallery_card'>
                <div className='galley-main'>
                  <img className='gallery_img' src={item.attributes.Image} alt='' />
                </div>
                <div className='gallery_txt'>{item.attributes.Body}</div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
      <Footer />
    </>
  );
}

export default ReportFirst;
