import React, { useState } from "react";
import "./DonateNow.css";
import PaymentImage from "../Assets/Images/payment-method-png-26072.png";
import StripeCheckout from "react-stripe-checkout";
import { message, Select } from "antd";

function DonateNow() {
  const onToken = (token) => {
    console.log(token);
  };
  const [amount, setamount] = useState("");
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleDonate = (e) => {
    e.preventDefault();
    if (amount === "") {
      message.error("Please Enter An Amount");
    } else {
      var options = {
        key:"rzp_live_IHKlS3VOnLT7xp",
        key_secret:"UMAQwjujI7ucabpnp9jBJrh4",
        amount:amount*100,
        currency:"INR",
        name:"Amity Donation",
        description:category,
        handler: function(response){
          alert(response.razorpay_payment_id);
        },
        notes:{
          address:"Razorpay Corporate office"
        },
        theme:{
          color:"#3399cc"
        }
      };
      var pay = new window.Razorpay(options);
      pay.open();
    }
  };
  const { Option } = Select;

  return (
    <>
      <div className="DonateNowStart">
        <div className="InsideOfDonate">
          <div className="FirstPartOfDonate">
            <div className="PaymentimageDiv">
              <img src={PaymentImage} />
            </div>
          </div>
          <div className="SecondPartOfDonate">
            <div className="PaymentHeader">Fill The Feelds</div>
            <div className="InputFeeldsPayment">
              <div className="InputTextDiv">
                <input
                  type="text"
                  id="InputFeeldsPayment"
                  placeholder="Full Name"
                  name="name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>

              <div className="InputTextDiv">
                <input
                  type="email"
                  id="InputFeeldsPayment"
                  placeholder="Email "
                  name="mail"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <div className="InputTextDiv">
                <Select
                  id="InputFeeldsPayment"
                  placeholder="Select Category"
                  className="AntdSelect"
                  onChange={(value) => setCategory(value)}
                >
                  <Option value="category1">Home Project</Option>
                  <Option value="category2">Child welfare</Option>
                  <Option value="category3">Others</Option>
                </Select>
              </div>

              <div className="InputTextDiv">
                <input
                  type="tel"
                  id="InputFeeldsPayment"
                  placeholder="Phone Number"
                  name="phoneNumber"
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>

              <div className="InputTextDiv">
                <input
                  type="number"
                  id="InputFeeldsPayment"
                  placeholder="Amount In INR"
                  value={amount}
                  onChange={(e) => setamount(e.target.value)}
                />
              </div>
              <div
                style={{
                  width: "100%",
                  height: "13%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "end",
                }}
              >
                <button className="DonateButton" onClick={handleDonate}>
                  Donate Now
                </button>
                {/* <StripeCheckout
                  token={onToken}
                  name="Amity India Donation"
                  amount={amount * 100}
                  currency="INR"
                  email={email}
                  style={{ height: "40px", width: "72%" }}
                  description="Raising Hope"
                  image="https://media.istockphoto.com/id/1344823866/photo/red-heart-symbol-is-put-by-persons-hand-into-slot-of-white-donation-box.jpg?b=1&s=170667a&w=0&k=20&c=jMXDRLuDuoM9huGU1LuzOCMBdA7g4E9E6XKuFATuXH0="
                  stripeKey="pk_test_51N0f5YSG5bnjN6TtjelmMCMurgG1fvtPCLPmYwy1PkeLK57xTAXIDWi7q1iHWskloDQ66d7iiVJHUnwLzomoXtBN005VxTWJro"
                /> */}
              </div>
            </div>
            <div className="InformationPayment">
              Only payments from Indian Banks (INR) will be accepted.
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DonateNow;


