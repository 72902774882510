import React from 'react'
import './AboutFirst.css'
import NavbarContent from '../Navbar/Navbar';
import AboutSecond from './AboutSecond';

function AboutFirst() {
  return (
    <>
    <div className='StartOfAbout'>
        <NavbarContent/>

    <div className='StartOfAboutText'>
        <div className='HeaderOfAbout'>
            Amity India
            <div className='hopediv'>
                RAISING HOPE
            </div>

        </div>
        <div className='AboutUSDiv'>
            About Us
        </div>
    </div>
    </div>
    <AboutSecond/>
    </>
  )
}

export default AboutFirst;