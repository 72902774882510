import React, { useEffect, useRef,useState } from 'react'
import './FifthPage.css'
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from 'react-router-dom';
import client from '../Config/Graphql/apolloclient';
import { gql } from '@apollo/client';
import {Spin, message} from "antd"


function FifthPage() {
  const sliderRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  const settings = {
    infinite: true,
    dots:true,
    speed: 1000,
    slidesToShow:4,
    slidesToScroll:1,
    autoplay:true,
    autoplaySpeed:2000,
    responsive: [
      {
        breakpoint: "768px",
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        }
      }
    ]
    
  }
  useEffect(() => {
    dataLoad();
  }, []);
  const dataLoad = () => {
    client
      .query({
         query: gql`
          query {
            missionForwards {
              data {
                attributes {
                  image
                }
              }
            }
          }
         `
      })
      .then(function (response) {
        setData(response.data.missionForwards.data);
        setLoading(false); 
        message.success('Missions loaded successfully'); 
      })
      .catch(function (error) {
        message.error('Error loading Missions'); 
      });
  }





  return (
    <div className='FifthpageStart'>
      <div className='headerofFifth'>
        Amity India's Mission Forward
      </div>
      <div className='ButtonForFifthpage'>
        <Link to='./DonateNow'>
      <button className="DonateButtonFifthpage">Donate Now</button>
      </Link>
      </div>
      <div className='SlidesInFifthDiv'>
      {loading ? (
        <Spin/>
        ) : (
        <div className='SlidesInFifth'>
          <Slider ref={sliderRef} {...settings}>
            {data.map((item,index) => (
          <div style={{width:'100%',height:'100%'}}>
          <div className='firstBoxOfFifth' style={{backgroundImage:`url(${item.attributes.image})`}}>
          </div>
          </div>
            ))}
          </Slider>
          <div className='ButtonDivFifthpage'>
            <Link to='Gallery'>
          <button className="NewsButtonFifthpageSlider">View All News</button>
            </Link>

          </div>

        </div>
)}
      </div>
        

    </div>
  )
}

export default FifthPage;