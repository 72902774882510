import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    gql,
  } from "@apollo/client";
  const client = new ApolloClient({
    uri: "https://server.bairuhatech.com/amity/graphql",
    cache: new InMemoryCache(),
  });
  
  export default client;  