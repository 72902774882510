import React from "react";
import "./AboutSecond.css";
// import AboutThird from './AboutThird'
import { Link } from "react-router-dom";
import Footer from "../Footer/Footer";

function AboutSecond() {
  return (
    <>
      <div className="AboutSecondStart">
        <div className="FirstpartAboutSecond">
          <div className="AboutSecond">ABOUT US</div>
          <div className="AboutSecondHeader">
            Give A Helping Hand To
            <br />A Needy People
          </div>
          <div className="AboutSecondTexts">
            Welcome to Amity India - Empowering Lives Through Compassion At
            Amity India, our purpose is rooted in transforming lives through
            collective compassion. Our aim is clear: to offer a helping hand to
            individuals facing challenges and adversity. We firmly believe that
            together, we can foster positive change and create a world where
            empathy and support are paramount. Our journey commences when
            individuals or local coordinators reach out to us, presenting cases
            that warrant attention and assistance. As the heart of Amity India,
            our team of dedicated volunteers springs into action. These
            compassionate individuals visit the locations in question, engaging
            in meaningful dialogues to grasp the full context of each situation.
            By liaising with key figures within the local community, we gather
            essential insights that guide our understanding of the case's
            complexities. Through these interactions and the valuable feedback
            we receive, we assess the significance of extending our aid. Should
            the situation require further intervention, we collaborate closely
            with the appointed local coordinator. In cases demanding more
            substantial support, Amity India takes the lead by assigning a
            proficient site engineer alongside the local coordinator. Together,
            we craft a detailed project plan that encompasses estimations,
            timelines, and a comprehensive action strategy. At this juncture, we
            rally the support of our dedicated members at Amity India. Through a
            streamlined process, we gather funds, ensuring complete transparency
            by providing donors with official receipts and heartfelt
            acknowledgments. We recognize the importance of trust and
            accountability, which is why we take direct control of project
            execution. Instead of disbursing funds to individuals, our seasoned
            Amity India team takes charge, ensuring that every step adheres to
            the established project plan. We meticulously document every
            endeavor we undertake, creating comprehensive project reports that
            stand as a testament to our commitment. These reports are securely
            archived and shared with pertinent stakeholders. To uphold our
            commitment to integrity, we subject our operations to regular
            audits, upholding transparency and ethical practices. In honor of
            the privacy and dignity of both contributors and beneficiaries, we
            uphold a strict policy of anonymity. Donors can rest assured that
            their contributions remain confidential, and recipients receive aid
            without compromising their self-respect. For instance, in scenarios
            involving medical expenses, we directly settle hospital bills,
            offering immediate relief. Likewise, our approach to food assistance
            ensures that supplies reach respective vendors or eateries,
            maintaining the dignity of those in need. Amity India stands as a
            platform that bridges compassion and action, paving the way for a
            world where empathy thrives and meaningful change flourishes. Join
            us in our mission to empower lives, one gesture of kindness at a
            time, as we strive to create a more compassionate and harmonious
            world for all.
          </div>
          <div className="ButtonSecondDiv">
            <Link to="/DonateNow">
              <button className="DonateButtonSecondAbout">Donate Now</button>
            </Link>
          </div>
        </div>
        <div className="AboutSecondpart">
          <div className="PictureInSecondpart"></div>
        </div>
      </div>
      {/* <AboutThird/> */}
      <Footer />
    </>
  );
}

export default AboutSecond;
