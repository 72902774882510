import "./FourthPage.css";
import {Spin, message} from "antd"
import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import client from "../Config/Graphql/apolloclient";
import { gql } from "@apollo/client";

function FourthPage() {
  const sliderRef = useRef(null);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true); 

  const settings = {
    infinite: true,
    dots: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: "768px",
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: false,
        },
      },
    ],
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    client
      .query({
        query: gql`
          query {
            ourProjects(pagination: { start: 0, limit: 20 }) {
              data {
                attributes {
                  Image
                  Body
                  Head
                }
              }
            }
          }
        `,
      })
      .then(function (response) {
        setData(response.data.ourProjects.data);
        setLoading(false); 
        message.success('Projects loaded successfully'); 
      })
      .catch(function (error) {
        console.log("Error occurred while fetching data:", error);
        message.error('Error loading Projects'); 
      });
  };

  return (
    <div className="FourthStart">
      <div className="SmallHead">OUR PROJECTS</div>
      <div className="MainHeadFourth">Donate To Charity Projects</div>
      <div className="SlidersInFourth">
        {loading ? (
        <Spin/>
        ) : (
          <Slider ref={sliderRef} {...settings}>
            {data.map((item, index) => (
              <div style={{ width: "100%" }} key={index}>
                <div className="SlidersFourth">
                  <div
                    className="Inside-SlidersFourth1"
                    style={{ backgroundImage: `url(${item.attributes.Image})` }}
                  ></div>
                  <div className="ProgressDiv">
                    <div className="ProgressState"></div>
                  </div>
                  <div className="Inside-SlidersFourth-Texts">
                    {item.attributes.Body}
                  </div>
                  <div className="Inside-SlidersFourth-Header">
                    {item.attributes.Head}
                  </div>
                  <div className="Inside-SlidersFourth-Button">
                    <Link to="./DonateNow">
                      <button
                        className="DonateButtonFourthpageSlider"
                        style={{ width: "200px" }}
                      >
                        Donate Now
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default FourthPage;
