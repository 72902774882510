import React from "react";
import "./Footer.css";
import instaLogo from "../Assets/Images/sm-insta.svg";
import fbLogo from "../Assets/Images/sm-fb.svg";
import twitLogo from "../Assets/Images/sm-twitter.svg";
import instalogo from "../Assets/Images/sm-insta.svg";
import facebooklogo from "../Assets/Images/sm-fb.svg";
import { FaWhatsappSquare } from "react-icons/fa";
import calllogo from "../Assets/Images/ico-call.svg";
import maillogo from "../Assets/Images/ico-mail.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="FooterStart">
      <div className="DivsOfFooter">
        <div className="AmityDescriptions">
          <div className="HeaderOfDescription">AMITY FORUM</div>
          <div className="DescriptionText">
            Amity India is an organisation which
            <br />
            is built on the concept of creating a<br />
            reliable platform which will link the
            <br />
            needy ones and the donors who can
            <br />
            help them. Only deserving cases are
            <br />
            put forward and bought under the
            <br />
            projects.
          </div>
          <div className="SocialMediaFooter">
            <a href="https://www.facebook.com/profile.php?id=61550534578626&mibextid=ZbWKwL">
              <img className="facebook-logo" src={facebooklogo} />
            </a>
            <a href="https://wa.me/918330016570">
              <FaWhatsappSquare color="#F56077" size={28} />
            </a>
            <a href="https://instagram.com/_amity_india_?igshid=NjIwNzIyMDk2Mg==">
              <img className="insta-logo" src={instalogo} />
            </a>
          </div>
          <div className="FooterDonateButton">
            <Link to="/DonateNow">
              <button className="LastButtonFooter">Donate Now</button>
            </Link>
          </div>
        </div>
        <div className="LatestNewsDiv">
          <div className="HeaderOfDescription">LATEST NEWS</div>
        </div>
        <div className="QuicklinksDiv1">
          <div className="HeaderOfDescription">QUICK LINKS</div>
          <div className="LinksLists">
            <ul>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/About">About</Link>
              </li>
              <li>
                <Link to="/Projects">Projects</Link>
              </li>
              <li>
                <Link to="/Applications">Applications</Link>
              </li>
              <li>
                <Link to="/Contacts">Contact</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="QuicklinksDiv">
          <div className="HeaderOfDescription2">QUESTIONS</div>
          <div className="LocationDiv">
            Amity India Charitable Trust
            <br />
            Hashi Mahal,
            <br />
            Kusumagiri PO, kakkanad
            <br />
            Eranakulam,Kerala,India.
          </div>
          <div className="ContactNumber">
            <img src={calllogo} />
            &nbsp;&nbsp;Office: +91 83300 16570
            <br />
            <img src={calllogo} />
            &nbsp;&nbsp;Admin: +91 83300 16570
            <br />
            <img src={calllogo} />
            &nbsp;&nbsp;Accounts: +91 83300 16570
          </div>
          <div className="MailDiv">
            <div
              style={{
                width: "20px",
                height: "18px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <img src={maillogo} />
            </div>{" "}
            amityforum@gmail.com
          </div>
        </div>
      </div>

      <div className="CopyrightDiv">
        Copyright © 2014amityforum.com. All rights reserved.
        <span style={{ color: "#F56077" }}></span>&nbsp;|&nbsp;
        <span style={{ color: "#F56077" }}>
          <Link to="./Privacy">Privacy Policy</Link>
        </span>
      </div>
    </div>
  );
}

export default Footer;
