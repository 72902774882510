import React from "react";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import { Navbar } from "react-bootstrap";
import facebooklogo from "../Assets/Images/sm-fb.svg";
import instalogo from "../Assets/Images/sm-insta.svg";
import twitlogo from "../Assets/Images/sm-twitter.svg";
import NavBarModel from "./NavbarModel";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaWhatsappSquare } from "react-icons/fa";

function NavbarContent() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="dark">
        <Container fluid>
          <div className="toggler-div" onClick={showModal}>
            <IoMenu color="white" size={25} />
          </div>

          <div className="SocialMedia-Div">
            <a href="https://www.facebook.com/profile.php?id=61550534578626&mibextid=ZbWKwL">
              <img className="facebook-logo" src={facebooklogo} />
            </a>
            <a href="https://wa.me/918330016570">
              <FaWhatsappSquare color="#F56077" size={28} />
            </a>
            <a href="https://instagram.com/_amity_india_?igshid=NjIwNzIyMDk2Mg==">
              <img className="insta-logo" src={instalogo} />
            </a>
          </div>

          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav style={{ width: "100%" }}>
              <div className="selection-div">
                <Link to="/" className="home-div active">
                  Home
                </Link>
                <Link to="/Projects" className="projects-div active">
                  Projects
                </Link>
                <Link to="/Applications" className="application-div active">
                  Applications
                </Link>
                <Link to="/Gallery" className="gallery-div active">
                  Gallery
                </Link>
                <Link to="/Report" className="gallery-div active">
                  Report
                </Link>
                <Link to="/Contacts" className="contact-div active">
                  Contact
                </Link>
                {/* <Link to="/About" className="about-div active">
                  About
                </Link> */}
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isModalOpen ? (
        <NavBarModel visible={isModalOpen} onCancel={handleCancel} />
      ) : null}
    </>
  );
}

export default NavbarContent;
