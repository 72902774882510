import React from "react";
import "./ThirdPage.css";
import {GiCherish} from 'react-icons/gi';
import {TbHomeHeart} from 'react-icons/tb';
import {FaHandHoldingHeart} from 'react-icons/fa';
import {TbHeartHandshake} from 'react-icons/tb';




function ThirdPage() {
  return (
    <div className="Thirdpage-start">
      <div className="InsideThirdPage">
        <div className="FirstBox">
          <div className="Inside-FirstBox" style={{lineHeight:1.7}}>
            <div style={{width:'100%',
            height:'35%',
            backgroundColor:'#e9e9e9',
            fontFamily:'var(--semiBold)',
            fontSize:'20px',
            textAlign:'center',
            display:'flex',
            alignItems:'end',
            justifyContent:'center',
            color:'#F56077'
            }}>
                Compliance to<br/>the law
            </div>
            Amity projects are <br/>
            committed to operating<br/> 
            in full compliance with<br/> 
            all relevant laws and <br/>
            regulations.This includes<br/>
             both national and international<br/>
            laws related to social <br/>
            welfare, poverty alleviation, <br/>
            and human rights.
          </div>
          <div className="CircleIcondiv">
            <div className="CircleIcon">
                <GiCherish size={80} color={"red"}/>

            </div>

          </div>
        </div>
        <div className="SecondBox">
        <div className="Inside-SecondBox" style={{lineHeight:1.7}}>
            <div style={{width:'100%',
            height:'35%',
            backgroundColor:'#c9c9c9',
            fontFamily:'var(--semiBold)',
            fontSize:'20px',
            textAlign:'center',
            display:'flex',
            alignItems:'end',
            justifyContent:'center',
            color:'#F56077'
            }}>
                Complete<br/>
                 confidentiality<br/>
                of the needy
            </div>
            Amity projects are dedicated<br/>
             to providing safe and secure <br/>
             housing to those who are homeless<r/>
              or living in inadequate or unsafe<br/>
               housing conditions. As such,<br/>
                these projects are subject<br/>
                 to a variety of legal and<br/>
                  regulatory requirements, <br/>
            which they take very seriously.
          </div>
          <div className="CircleIcondiv">
            <div className="CircleIcon">
                <TbHomeHeart size={80} color={"red"}/>

            </div>
            </div>

        </div>
        <div className="ThirdBox">
        <div className="Inside-ThirdBox" style={{lineHeight:1.7}}>
            <div style={{width:'100%',
            height:'35%',
            backgroundColor:'#e9e9e9',
            fontFamily:'var(--semiBold)',
            fontSize:'20px',
            textAlign:'center',
            display:'flex',
            alignItems:'end',
            justifyContent:'center',
            color:'#F56077'
            }}>
                Transparency in<br/>
                 Activities
                
            </div>
            Amity projects operate<br/>
             in compliance with all <br/>
             relevant laws and regulations<br/>
              related to social welfare,<br/>
               housing, and human rights.<br/>
                This includes compliance <br/>
                with building codes and <br/>
                safety regulations, as<br/>
                 well as adherence to
                laws relate<br/>
             to zoning and land use.<br/> 
          </div>
          <div className="CircleIcondiv">
            <div className="CircleIcon">
                <FaHandHoldingHeart size={70} color={"red"}/>

            </div>
            </div>
        </div>
        <div className="FourthBox">
        <div className="Inside-FourthBox" style={{lineHeight:1.7}}>
            <div style={{width:'100%',
            height:'35%',
            backgroundColor:'#c9c9c9',
            fontFamily:'var(--semiBold)',
            fontSize:'20px',
            textAlign:'center',
            display:'flex',
            alignItems:'end',
            justifyContent:'center',
            color:'#F56077'
            }}>
                Cater unknown<br/>
                 Deserving<br/>
                 People
                
            </div>
            Overall, shelter projects<br/>
             prioritize legal compliance<br/>
              and confidentiality <br/>
              in all of their activities,<br/>
               while also promoting the<br/>
                principles of inclusivity,<br/>
                 respect, and dignity<br/>
                  for all individuals<br/>
                   who seek their assistance.<br/>




          </div>
          <div className="CircleIcondiv">
            <div className="CircleIcon">
                <TbHeartHandshake size={70} color={"red"}/>

            </div>
            </div>
        </div>
      </div>
    </div>
  );
}

export default ThirdPage;
