import "./App.css";
import FifthPage from "./Fifthpage/FifthPage";
import Footer from "./Footer/Footer";
import FourthPage from "./FourthPage/FourthPage";
import Secondpage from "./SecondPage/Secondpage";
import Sixthpage from "./SixthPage/Sixthpage";
import StartingPage from "./StartingPage/StartingPage";
import ThirdPage from "./Thirdpage/ThirdPage";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutFirst from "./About/AboutFirst";
import ApplicationFirst from "./Applications/ApplicationFirst";
import ProjectsFirst from "./Projects/ProjectsFirst";
import ContactFirst from "./Contact/ContactFirst";
import GalleryFirst from "./Gallery/GalleryFirst";
import DonateNow from "./DonateNow/DonateNow";
import HomeForPoors from "./ReadmorePages/HomeForPoors";
import ClothsOrphans from "./ReadmorePages/ClothsOrphans";
import CareSenior from "./ReadmorePages/CareSenior";
import HandsForChildren from "./ReadmorePages/HandsForChildren";
import ApplicationForm from "./Applications/component/form";
import Privacy from "./Privacy/privacy";
import ReportFirst from "./Report/ReportFirst";


function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            exact
            path="/"
            element={
              <div>
                <StartingPage />
                <Secondpage />
                <ThirdPage />
                <FourthPage />
                <FifthPage />
                <Sixthpage />
                <Footer />
              </div>
            }
          />
          <Route path="/About" element={<AboutFirst />} />
          <Route path="/Applications" element={<ApplicationFirst />} />
          <Route path="/Projects" element={<ProjectsFirst />} />
          <Route path="/Contacts" element={<ContactFirst />} />
          <Route path="/Gallery" element={<GalleryFirst />} />
          <Route path="/Report" element={<ReportFirst />} />
          <Route path="/DonateNow" element={<DonateNow />} />
          <Route path="/HomeForPoors" element={<HomeForPoors />} />
          <Route path="/ClothesOfOrphans" element={<ClothsOrphans />} />
          <Route path="/CareForSeniors" element={<CareSenior />} />
          <Route path="/HandsForChildrens" element={<HandsForChildren />} />
          <Route path="/Application-Form" element={<ApplicationForm />} />
          <Route path="/privacy" element={<Privacy/>} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
