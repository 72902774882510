import "./StartingPage.css";
import imageSlide from "./data";
import { useState, useEffect } from "react";
import NavbarContent from "../Navbar/Navbar";
import { Link } from "react-router-dom";


function StartingPage() {
  const [currentState, setcurrentState] = useState(0);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (currentState === 2) {
        setcurrentState(0);
      } else {
        setcurrentState(currentState + 1);
      }
    }, 4000);
    return () => clearTimeout(timer);
  });
  const bgimageStyle = {
    backgroundImage: `url(${imageSlide[currentState].url})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    height: "100%",
  };

  return (
    <div className="LandingPage">
      <div style={bgimageStyle}>
        <NavbarContent />
        <div className="discription">
          <div className="SmalltextDiv">Amity India</div>
          <div className="Text-div">
            <div className="raisingDiv">RAISING HOPE</div>
            A Humble Effort In
            <br />
            Field Of Humanitarian
            <br />
            Services.
          </div>
          <div className="DonateVideosButtonDiv">
            <div className="Inside-div">
              <Link to='./DonateNow'>
              <button className="Starting-DonateButton">Donate Now</button>
              </Link>
              {/* <button className="Starting-VideosButton">Watch Our Videos</button> */}
            </div>
          </div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default StartingPage;
