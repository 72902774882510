import React, { useState, useEffect } from "react";
import "./ProjectsSecond.css";
import Footer from "../Footer/Footer";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import client from "../Config/Graphql/apolloclient";
import { gql } from "@apollo/client";

function ProjectsSecond() {
  const [data, setData] = useState([]);

  useEffect(() => {
    dataEntry();
  }, []);
  const dataEntry = () => {
    client
      .query({
        query: gql`
        query {
            amityProjects (pagination: { start: 0, limit: 20 }){
              data {
                attributes {
                  Image
                  Body
                }
              }
            }
          }
        `,
      })
      .then(function (response) {
        console.log("this is the response ==>", response);
        setData(response.data.amityProjects.data);
      })
      .catch(function (error) {
        console.log("Error occurred while fetching data:", error);
      });
  };
  return (
    <>
      <div className="StartOfSecond">
          <div className="SectionsInContainersproject">
            <Row>
            {data.map((item, index) => (
              <Col md={3} sm={6} xs={12} key={index}>
                  <div className="FirstBoxContainer1">
                    <div
                      className="BoxImages"
                      style={{
                        backgroundImage: `url(${item.attributes.Image})`,
                      }}
                    ></div>
                    <div className="ProgressbarDiv">
                      <div className="ProgressBarState"></div>
                    </div>
                    <div className="ContentText">{item.attributes.Body}</div>
                    <div className="ButtonOfContentBox">
                      <Link to="/DonateNow">
                        <button className="DonateButtonContentBox">
                          Donate Now
                        </button>
                      </Link>
                    </div>
                  </div>
              </Col>
            ))}
            </Row> 
          </div>
      </div>
      <Footer />
    </>
  );
}

export default ProjectsSecond;
