import React from 'react'
import './ProjectsFirst.css'
import NavbarContent from '../Navbar/Navbar';
import ProjectsSecond from './ProjectsSecond';

function ProjectsFirst() {
  return (
    <>
    <div className='StartingOfProjects'>
      <NavbarContent/>
      <div className='ProjectsTextsAndHeader'>
        <div className='TextOfProjects'>
          Home Causes
        </div>
        <div className='HeaderOfPojects'>
          Amity Projects
        </div>

      </div>
        
    </div>
    <ProjectsSecond/>
    </>
  )
}

export default ProjectsFirst;