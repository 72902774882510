import React from "react";
import "./privacy.css";

function Privacy() {
  return (
    <div>
      <div className="MainContainer" style={{ height: "100vh" }}>
        <div className="headerSection">Privacy Policy</div>
        <div className="AboutTextdiv">
        <b>User information on this site is kept strictly confidential:- &nbsp;</b> The site
          may, however, use this information from time to time for purposes such
          as corresponding with the users for various issues. We shall duly seek
          permission from the users in such cases. We collect personal
          information when you create an account or edit your account
          information, or make a donation. Some of this information is a legal
          requirement of the Government of India, such as your postal address.
          Other information is used to facilitate your use of the site, such as
          knowing your taxpayer status so that we show you options that you are
          eligible to make, as well as amounts in your local currency. We do not
          collect or have access to the credit card numbers or bank account
          information our third-party vendors (Razorpay) collect
          to process online transactions. 
          Amity India will not share, rent, or
          sell your personal information to third parties. By default, you will
          also receive monthly newsletters as well as other Amity India emailers
          from time to time. In case you would not like to receive these
          newsletters and emailers, you can send an email to
          amitycharitabletrust@gmail.com with the word 'Unsubscribe' in the
          subject line, and we will remove your name from the list. You can also
          unsubscribe using the Unsubscribe link in the emailer received. We
          take privacy seriously and we value yours. In case you have any
          further questions or concerns about our Privacy Policy, please write
          to us at amitycharitabletrust@gmail.com.
          <br/>
          <br/>
           <b>Cookie Policy:-&nbsp;</b>
          What Are
          Cookies? Cookies are files or pieces of information that may be stored
          on your computer (or other internet-enabled devices, such as a
          smartphone or tablet) when you visit our site. A cookie will usually
          contain the name of the website from which the cookie has come from,
          the "lifetime" of the cookie (i.e. how long it will remain on your
          device), and a value, which is usually a randomly generated unique
          number/email id. What do we use cookies for? We use cookies to make
          the site easier to use and to better tailor the experience to your
          interests and needs. Cookies may also be used to help speed up your
          future activities and experience on our site. We also use cookies to
          compile anonymous, aggregated statistics that allow us to understand
          how people use our site and to help us improve its structure and
          content. Disabling Cookies You can prevent the setting of cookies by
          adjusting the settings on your browser (see your browser Help for how
          to do this). Be aware that disabling cookies will affect the
          functionality of this and many other websites that you visit.
          Disabling cookies will usually result in also disabling certain
          functionality and features of this site. Therefore, it is recommended
          that you do not disable cookies. Third-Party Cookies In some special
          cases, we also use cookies provided by trusted third parties. The
          following section details which third-party cookies you might
          encounter through this site. This site uses Google Analytics and
          Facebook Pixel, which are the most widespread and trusted analytics
          solutions on the web for helping us understand how you use the site
          and ways that we can improve your experience. These cookies may track
          things such as how long you spend on the site and the pages you visit,
          so we can continue to produce engaging content. For more information
          on Google Analytics cookies, see the official Google Analytics page.
          Hopefully, that has clarified things for you. As previously mentioned,
          if there is something that you aren't sure whether you need or not,
          it's usually safer to leave cookies enabled in case it does interact
          with one of the features you use on our site. However, if you are
          still looking for more information, then you can contact us through
          one of our preferred contact methods. EU Privacy/Data Protection
          Rights EU General Data Protection Regulation (GDPR) has provided the
          below rights to the EU residents: Right to information - including
          contact details for the Data Protection Officer (DPO), the purposes
          for processing Personal Information, and the rights of the individual.
          Right to access the Personal Information that is processed. Right to
          erasure ("Right to be forgotten"). Right to rectification. Right to
          restriction of processing. Right to data portability of the Personal
          Information supplied to Amity India by the EU resident. Right to
          object (opt-out) to processing (unless Amity India otherwise has
          compelling legitimate grounds). EU residents can exercise these rights
          by raising a request directly with our Data Protection Officer (DPO)
          at amitycharitabletrust@gmail.com.
          <br/>
          <br/>
          <b>Disclaimer:-&nbsp;</b>
          amityforum.org shall
          endeavor to put up only such information on the site, as it has
          verified from the NGOs, or otherwise as being true to the best of
          their knowledge and belief. However, amityforum.org does not accept
          any liability due to the inaccuracy of information or data provided on
          this site. Any liabilities arising out of any act or omission by a
          visitor to this site, by relying on or using the information provided
          on this site, shall be solely to the account of such visitor.
          amityforum.org will strive to ensure that the NGOs send tax exemption
          certificates and feedback reports to the donors within reasonable time
          limits. amityforum.org shall exert reasonable influence at its
          command, including the withdrawal of non-compliant NGOs from the site,
          if, in its opinion, the situation merits such action. However,
          amityforum.org is indemnified by donors on this site from any
          liabilities arising out of any kind of non-compliance by the NGOs.
          amityforum.org disclaims any liability, legal or otherwise, that may
          arise due to the act, omission, or otherwise of any visitor to this
          site or caused by the same. Visitors to the site acknowledge that
          amityforum.org is purely a "facilitator" for transactions between the 
          NGOs and visitors and, as such, stand indemnified from any liabilities
          that may arise due to the same. Visitors also acknowledge that
          visiting this site is an implicit acceptance of this disclaimer on
          their part.
        </div>
        <div className="SubFooter">@amityforum, 2023 All Right Reserved</div>
      </div>
    </div>
  );
}

export default Privacy;
