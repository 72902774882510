import React, { useState } from 'react';
import './ContactFirst.css';
import NavbarContent from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { RiNavigationFill } from 'react-icons/ri';
import { IoLocation } from 'react-icons/io5';
import { IoCall } from 'react-icons/io5';
import { BsGlobeCentralSouthAsia } from 'react-icons/bs'



function ContactFirst() {
        const [emailNote, setEmailNote] = useState('')
        const [email, setEmail] = useState('');
        const [fullName, setFullName] = useState('');
        const [subject, setSubject] = useState('');
        const [message, setMessage] = useState('');
        const [emailError, setEmailError] = useState('');

        const handleEmailChange = (event) => setEmail(event.target.value);
        const handleFullNameChange = (event) => setFullName(event.target.value);
        const handleSubjectChange = (event) => setSubject(event.target.value);
        const handleMessageChange = (event) => setMessage(event.target.value);
        const emailChange = (event) => {
            setEmailNote(event.target.value);
      
          if (!event.target.value.includes('@')) {
            setEmailError('Please enter a valid email address');
          } else if (event.target.value.toLowerCase() !== event.target.value) {
            setEmailError('Email must contain only lowercase letters');
          } else {
            setEmailError('');
          }
        };


      
        const handleEmailClick = () => {
           
          if (!email || !fullName || !subject || !message ) {
            alert('Please Fill All The Fields.');
            return; 
          }
          else{
            alert('Submitting The Form')
          }

          const mailtoUrl = `mailto:${"amityforum.com"}?subject=${subject}& ${fullName},\n\n`;
          window.location.href = mailtoUrl; 

        };
    
  return (
    <>
    <div className='ContactStart'>
    <NavbarContent/>
    <div className='FirstPartOfContact'>
        <div className='SubHeadingOfContact'>
            Home Contact Us
        </div>
        <div className='HeaderOfContact'>
            Contact AmityForum
        </div>
    </div>

    </div>
    <div className='SecondPageOfContact'>
        {/* <div className='ContainerForContactes'>
            <div className='ContainerContactDiv'>
                <div className='AddressDiv'>
                    <div className='ContactLogoDiv'>
                        <IoLocation size={40} color='white'/>
                    </div>
                    <div className='ContactHeader'>
                        ADDRESS
                    </div>
                    <div className='TextsOfContact'>
                        <div className='AddressHeader'>
                            Amity India Charitable Trust
                        </div>
                        REG NO:<br/>
                        Location,<br/>
                        Location,<br/>
                        District

                    </div>

                    </div>

                    <div className='PhoneNumberDiv'>
                    <div className='ContactLogoDiv'>
                        <IoCall size={40} color='white'/>
                    </div>
                    <div className='ContactHeader'>
                        PHONE
                    </div>
                    <div className='TextsOfContact'>
                    <div className='AddressHeader'>
                        </div>
                        Office:&nbsp;+91 9999999999<br/>
                        Admin:&nbsp;+91 9999999999<br/>
                        Accounts:&nbsp;+91 9999999999


                    </div>

                    </div>
                    <div className='EmailDivContact'>
                    <div className='ContactLogoDiv'>
                        <RiNavigationFill size={40} color='white'/>
                    </div>
                    <div className='ContactHeader'>
                        EMAIL
                    </div>
                    <div className='TextsOfContact'>
                    <div className='AddressHeader'>
                        </div>
                        amityforum@gmail.com

                    </div>

                    </div>
                    <div className='WebsiteDiv'>
                    <div className='ContactLogoDiv'>
                        <BsGlobeCentralSouthAsia size={40} color='white'/>
                    </div>
                    <div className='ContactHeader'>
                        WEBSITE
                    </div>
                    <div className='TextsOfContact'>
                    <div className='AddressHeader'>
                        </div>
                        www.amityforum.org

                    </div>

                </div>
                
            </div>

        </div> */}
        <div className='SendingMessageDiv'>
            <div className='MessageTextDiv'>
                <div className='FirstPartOfSendMessageDiv'>
                    <div className='ContactUsHeading'>
                        Contact Us
                    </div>
                    <div className='NameInContactDiv'>
                            <input className='FullNameInput' placeholder='FULL NAME' onChange={handleFullNameChange}  type='text' required></input>
                    </div>
                    <div className='NameInContactDiv' onChange={emailChange}>
                    <input className='FullNameInput' placeholder='EMAIL' onChange={handleEmailChange} type='email' name='email' required>
                    </input>
                    </div>
                    {emailError && <div style={{color: 'red', fontSize:"12px"}}>{emailError}</div>}
                    <div className='NameInContactDiv'>
                    <input className='FullNameInput' placeholder='SUBJECT' onChange={handleSubjectChange} type='text' required></input>
                    </div>
                    <div className='MessageInContactDiv'>
                            <textarea className='MessageInput' placeholder='MESSAGE' onChange={handleMessageChange} type='text' required></textarea>
                    </div>  
                    <div className='SendMessageDiv'>
                        <button className='SendmessageButton' onClick={handleEmailClick}>
                            Send Message</button>

                    </div>
                </div>
                <div className='SecondPartOfSendMessageDiv'>
                </div>
            </div>
        </div>
    </div>
    <Footer/>
    </>
    
  )
};




export default ContactFirst;