import React, { useState } from "react";
import "./ApplicationFirst.css";
import NavbarContent from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import moment from "moment";

import {
  Button,
  Form,
  Input,
  Col,
  Row,
  Radio,
  Upload,
  DatePicker,
  Select,
} from "antd";
import axios from "axios";
import { multipleImagesToS3, uploadImageToS3 } from "../Config/s3Upload";
import { HiPlus } from "react-icons/hi";

function ApplicationFirst() {
  const [showForm, setShowForm] = useState();
  const [image, setImage] = useState("");

  const formLayout = {
    labelCol: { span: 24 }, // Corrected
    wrapperCol: { span: 22 }, // Corrected
  };
  const formLayout2 = {
    labelCol: { span: 24 }, // Corrected
    wrapperCol: { span: 23 }, // Corrected
  };
  const itemNameStyle = {
    fontWeight: "600",
    fontSize: "14px",
    color: "white",
    letterSpacing: "1px",
  };
  let imageurl = image;
  const handleFileUpload = async ({ val }) => {
    const fileName = `${moment().format("YYYY-MM-DDHH:mm:ss")}amity-image.png`;
    const file = val;
    const response = await uploadImageToS3(file, fileName);
    setImage(response.Location);
  };

  const numberRegExp = /^[0-9]*$/;
  const dateFormatList = "DD/MM/YYYY";
  const { Option } = Select;
  const onFinish = (values) => {
    const requestobj = {
      data: {
        adhaar: values.adhaar,
        application_category: values.application_category,
        application_name: values.application_name,
        application_photo: imageurl,
        contact_number: values.contact_number,
        contact_other: values.contact_other,
        dailyincome: values.dailyincome,
        district: values.district,
        date_of_birth: moment(values.dob).format("DD.MM.YYYY"),
        email: values.email,
        gender: values.gender,
        house_name: values.house_name,
        job: values.job,
        panchayath: values.panchayath,
        pincode: values.pincode,
        place: values.place,
        placeofjob: values.placeofjob,
        post: values.post,
        resident_place: values.resident_place,
        state: values.state,
        taluk: values.taluk,
        town: values.town,
        ward_member_name: values.ward_member_name,
        ward_member_number: values.ward_member_number,
        ward_no: values.ward_no,
        whatsapp_no: values.whatsapp_no,
        health_insurance: values.health_insurance,
        other_info: values.other_info,
        owned_vehicle: values.owned_vehicle,
        ration_card: values.ration_card,
        relation: values.relation,
        relative_age: values.relative_age,
        relative_job: values.relative_job,
        relative_name: values.relative_name,
        relative_remark: values.relative_remark,
        relative_salary: values.relative_salary,
        shelter_contact_person: values.shelter_contact_person,
        father_spouse: values.father_spouse,
        additional_document: image2,
      },
    };

    let url =
      "https://amityforum-cms-17e0731b42ce.herokuapp.com/api/applicationforms";
    axios
      .post(url, requestobj)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const [inputCount, setInputCount] = useState(1);
  const [image2, setImage2] = useState("");

  const handleFileUpload2 = async ({ val }) => {
    const fileName = `${moment().format("YYYY-MM-DDHH:mm:ss")}amity-image.png`;
    const file = val;
    const response = await multipleImagesToS3(file, fileName);
    setImage2(response.Location);
  };

  const handleAddInput = () => {
    setInputCount(inputCount + 1);
  };

  return (
    <>
      <div className="StartOfApplication">
        <NavbarContent />
        <div className="ApplicationFirstPageDiv">
          <div className="SubheadingApplication">Home Applications</div>
          <div className="HeadingApplication">Amity India Applications</div>
        </div>
      </div>
      <div className="InstructionPage">
        <div className="insteuctionPage-headTxt">
          <div className="HeadOfinstruction">Application Guidlines</div>
          <div className="InstructionsDiv">INSTRUCTIONS</div>
          <div className="InstructionListsDiv">
            <div className="InstructionLists">
              <ul>
                <li>
                  Fill all the column in the application and write NIL which is
                  not required
                </li>
                <li>Write job title and salary accurately</li>
                <li>
                  Applications will be subject to due diligence and interview.
                </li>
                <li>Interview will be conducted directly at Pulikal office.</li>
                <li>
                  Applications other than medical cases will be considered on a
                  first-come, first-served basis
                </li>
                <li>Only 100% eligible applications will be considered.</li>
                <li>Documents to be provided at the time of request</li>
                <li>
                  The Trust Board will have the authority to take any decision
                  on the applications
                </li>
                <li>
                  If the application is approved, the Pulikal officer should
                  come and sign the consent form
                </li>
                <li>
                  The project will be overseen by a coordinator appointed by the
                  shelter.
                </li>
                <li>
                  The applicant must accept the decision taken by the
                  Coordinator with the approval of the Shelter Board.
                </li>
                <li>
                  Funds will be allocated only for the activities undertaken by
                  the shelter.
                </li>
                <li>Only the funds received for each project will be given.</li>
                <li>
                  Financial transactions will be done through the entire bank
                  account only.
                </li>
                <li>
                  Shelter will be provided directly to the respective party as
                  per requirement eg Hospital - Shops - .....
                </li>
                <li>
                  The next installment will be issued after receipt of the exact
                  bill and voucher of the given funds at the shelter.
                </li>
                <li>
                  The Shelter Trust Board shall have the power to terminate the
                  assistance at any time.
                </li>
                <li>Only one aid is allowed per person.</li>
                <li>
                  Application and accompanying documents will not be returned
                  under any circumstances.
                </li>
                <li>
                  Different applications have specific rules and conditions so
                  check them and apply accordingly.
                </li>
                <li>
                  APPLICANT PHOTO - 500Kb size limit Only passport size photo
                  will be considered. Otherwise will be REJECT.
                </li>
                <li>
                  Upload the required documents in the ADDITIONAL DOCUMENT
                  section.
                </li>
                <li>
                  Application will be REJECT if required documents are not
                  present.
                </li>
                <li>MAIN DOCUMENTS:</li>
                Ward Member's Letter
                <br />
                Ration Card,
                <br />
                Copy of Adharcard
              </ul>
            </div>
            <div className="MobileInstructionList">
              <ul>
                <li>
                  The applicant should submit the application in <br />
                  his own name
                </li>
                <li>
                  Fill all the column in the application and write
                  <br /> NIL which is not required
                </li>
                <li>
                  Write job title and salary accurately
                  <br />
                </li>
                <li>
                  Applications will be subject to due diligence
                  <br /> and interview.
                </li>
                <li>
                  Interview will be conducted directly at Pulikal
                  <br /> office.
                </li>
                <li>
                  Applications other than medical cases will be
                  <br /> considered on a first-come, first-served basis
                </li>
                <li>
                  Only 100% eligible applications will be <br />
                  considered.
                </li>
                <li>
                  Documents to be provided at the time of
                  <br /> request
                </li>
                <li>
                  The Trust Board will have the authority to take
                  <br /> any decision on the applications
                </li>
                <li>
                  If the application is approved, the Pulikal officer
                  <br /> should come and sign the consent form
                </li>
                <li>
                  The project will be overseen by a coordinator
                  <br /> appointed by the shelter.
                </li>
                <li>
                  The applicant must accept the decision taken
                  <br /> by the Coordinator with the approval of the Shelter
                  Board.
                </li>
                <li>
                  Funds will be allocated only for the activities
                  <br /> undertaken by the shelter.
                </li>
                <li>
                  Only the funds received for each project will be <br />
                  given.
                </li>
                <li>
                  Financial transactions will be done through the
                  <br /> entire bank account only.
                </li>
                <li>
                  Shelter will be provided directly to the
                  <br /> respective party as per requirement eg Hospital
                  <br /> - Shops - .....
                </li>
                <li>
                  The next installment will be issued after receipt
                  <br /> of the exact bill and voucher of the given funds <br />
                  at the shelter.
                </li>
                <li>
                  The Shelter Trust Board shall have the power to
                  <br /> terminate the assistance at any time.
                </li>
                <li>Only one aid is allowed per person.</li>
                <li>
                  Application and accompanying documents will <br />
                  not be returned under any circumstances.
                </li>
                <li>
                  Different applications have specific rules and
                  <br /> conditions so check them and apply
                  <br /> accordingly.
                </li>
                <li>
                  APPLICANT PHOTO - 500Kb size limit Only
                  <br /> passport size photo will be considered.
                  <br /> Otherwise will be REJECT.
                </li>
                <li>
                  Upload the required documents in the <br />
                  ADDITIONAL DOCUMENT section.
                </li>
                <li>
                  Application will be REJECT if required
                  <br /> documents are not present.
                </li>
                <li>MAIN DOCUMENTS:</li>
                Ward Member's Letter
                <br />
                Ration Card,
                <br />
                Copy of Adharcard
              </ul>
            </div>
          </div>
          {showForm ? null : (
            <div className="ButtonOfApplyDiv">
              <button
                className="ButtonOfApply"
                onClick={() => setShowForm(!showForm)}
              >
                Apply Now
              </button>
            </div>
          )}
        </div>
      </div>

      {showForm ? (
        <div className="applictn_formConatiner">
          <div className="applictn_formDiv2">
            <div className="applictn_headTxt">Application Form</div>
            {/* <div className="applictn-formContainer"> */}
            <Form onFinish={onFinish}>
              <Col md={24}>
                <Col md={24}>
                  <div className="form-item">
                    <div className="form-Label">APPLICATION NAME</div>
                    <Form.Item
                      {...formLayout2}
                      name={"application_name"}
                      rules={[
                        {
                          required: true,
                          message: "Please input your username!",
                        },
                      ]}
                    >
                      <Input size="large" />
                    </Form.Item>
                  </div>
                </Col>
                <Row>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">CONATCT NO</div>
                      <Form.Item
                        {...formLayout}
                        name={"contact_number"}
                        rules={[
                          {
                            required: true,
                            pattern: numberRegExp,
                            message: "Please enter numbers only.",
                          },
                        ]}
                      >
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">CONATCT (Other)</div>
                      <Form.Item
                        name={"contact_other"}
                        {...formLayout}
                        rules={[
                          {
                            pattern: numberRegExp,
                            message: "Please enter numbers only.",
                          },
                        ]}
                      >
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">WHATSAPP NO</div>
                      <Form.Item name={"whatsapp_no"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">E-MAIL</div>
                      <Form.Item name={"email"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Col md={24}>
                  <div className="form-item">
                    <div className="form-Label">APPLICATION CATEGORY</div>
                    <Form.Item name={"application_category"} {...formLayout2}>
                      <Input size={"large"} />
                    </Form.Item>
                  </div>
                </Col>
                <Row>
                  <Col md={16}>
                    <div className="form-item2">
                      <div className="form-Label">GENDER</div>
                      <Form.Item name={"gender"} {...formLayout}>
                        <Radio.Group>
                          <Radio value="male" style={itemNameStyle}>
                            Male
                          </Radio>
                          <Radio value="female" style={itemNameStyle}>
                            {" "}
                            Female{" "}
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item2">
                      <div className="form-Label">APPLICANT PHOTO</div>
                      <Form.Item name="application_photo" {...formLayout}>
                        <Input
                          type="file"
                          onChange={(event) =>
                            handleFileUpload({
                              val: event.target.files[0],
                            })
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">DATE OF BIRTH</div>
                      <Form.Item name={"dob"} {...formLayout}>
                        <DatePicker
                          style={{ width: 300 }}
                          defaultValue={moment("01/01/2015", dateFormatList)}
                          format={dateFormatList}
                        />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">ADHAAR NO</div>
                      <Form.Item
                        name={"adhaar"}
                        {...formLayout}
                        rules={[
                          {
                            pattern: numberRegExp,
                            message: "Please enter numbers only.",
                          },
                        ]}
                      >
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Col md={24}>
                  <div className="form-item">
                    <div className="form-Label">FATHER/SPOUSE NAME</div>
                    <Form.Item name={"father_spouse"} {...formLayout2}>
                      <Input size={"large"} />
                    </Form.Item>
                  </div>
                </Col>

                <Row>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">HOUSE NAME</div>
                      <Form.Item name={"house_name"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">PLACE</div>
                      <Form.Item name={"place"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">Town</div>
                      <Form.Item name={"town"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">PANCHAYATH</div>
                      <Form.Item name={"panchayath"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">TALUK</div>
                      <Form.Item name={"taluk"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">POST</div>
                      <Form.Item name={"post"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">DISTRICT</div>
                      <Form.Item name={"district"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">PINCODE</div>
                      <Form.Item name={"pincode"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">RESIDENT PLACE</div>
                      <Form.Item name={"resident_place"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">STATE</div>
                      <Form.Item name={"state"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">JOB</div>
                      <Form.Item name={"job"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">PLACE OF JOB</div>
                      <Form.Item name={"placeofjob"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row></Row>
                <Row>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">DAILY INCOME</div>
                      <Form.Item name={"dailyincome"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">WARD NUMBER</div>
                      <Form.Item name={"ward_no"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row className="applictn_realtiveDetails">
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">WARD MEMBER NAME</div>
                      <Form.Item name={"ward_member_name"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="form-item">
                      <div className="form-Label">WARD MEMBER PHONE-NUMBER</div>
                      <Form.Item name={"ward_member_number"} {...formLayout}>
                        <Input size={"large"} />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Col md={24} className="applictn_realtiveDetails">
                  <Row>
                    <div className="applictn-formsubHed">
                      കുടുംബത്തിലെ ഓരോ അംഗത്തിന്റെയും വിവരങ്ങള്‍ താഴെ കോളത്തില്‍
                      ചേര്‍ക്കുക
                    </div>
                    <Col md={8}>
                      <div className="form-item">
                        <div className="form-Label">RELATIVE NAME</div>
                        <Form.Item name={"relative_name"} {...formLayout2}>
                          <Input size={"large"} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="form-item">
                        <div className="form-Label">RELATIVE AGE</div>
                        <Form.Item name={"relative_age"} {...formLayout2}>
                          <Input size={"large"} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="form-item">
                        <div className="form-Label">RELATION</div>
                        <Form.Item name={"relation"} {...formLayout2}>
                          <Input size={"large"} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={8}>
                      <div className="form-item">
                        <div className="form-Label">JOB </div>
                        <Form.Item name={"relative_job"} {...formLayout2}>
                          <Input size={"large"} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="form-item">
                        <div className="form-Label">SALARY </div>
                        <Form.Item name={"relative_salary"} {...formLayout2}>
                          <Input size={"large"} />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col md={8}>
                      <div className="form-item">
                        <div className="form-Label">REMARKS </div>
                        <Form.Item name={"relative_remark"} {...formLayout2}>
                          <Input size={"large"} />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Row style={{ marginTop: 40 }}>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">
                        ANY PENDING APPLICATIONS ?
                      </div>
                      <Form.Item
                        name={"shelter_contact_person"}
                        {...formLayout2}
                      >
                        <Select>
                          <Option key={"Yes"}>Yes</Option>
                          <Option key={"No"}>No</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">
                        RECEIVING ANY SERVICE FROM US ?
                      </div>
                      <Form.Item {...formLayout2}>
                        <Select>
                          <Option key={"Yes"}>Yes</Option>
                          <Option key={"No"}>No</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">OTHER INFORMATIONS</div>
                      <Form.Item name={"other_info"} {...formLayout2}>
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">RESIDENT PLACE</div>
                      <Form.Item name={"resident_place"} {...formLayout2}>
                        <Select>
                          <Option key={"owned"}>Owned</Option>
                          <Option key={"rent"}>Rent</Option>
                          <Option key={"cottage"}>Cottage</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">HEALTH INSURANCE</div>
                      <Form.Item name={"health_insurance"} {...formLayout2}>
                        <Select>
                          <Option key={"Yes"}>Yes</Option>
                          <Option key={"No"}>No</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">RESIDENT LAND</div>
                      <Form.Item name={"user_land"} {...formLayout2}>
                        <Select>
                          <Option key={"Yes"}>Yes</Option>
                          <Option key={"No"}>No</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">RESIDENT LAND CENT</div>
                      <Form.Item name={"user_land_cent"} {...formLayout2}>
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>

                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">RATION CARD</div>
                      <Form.Item name={"ration_card"} {...formLayout2}>
                        <Select>
                          <Option key={"owned"}>Owned</Option>
                          <Option key={"rented"}>Rented</Option>
                          <Option key={"no"}>No</Option>
                        </Select>
                      </Form.Item>
                    </div>
                  </Col>
                  <Col md={8}>
                    <div className="form-item">
                      <div className="form-Label">PRIVATELY OWNED VEHICLE</div>
                      <Form.Item name={"owned_vehicle"} {...formLayout2}>
                        <Input />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Col>
              {Array.from({ length: inputCount }).map((_, index) => (
                <Row key={index}>
                  <Col md={8}>
                    <div className="form-item2">
                      <div className="form-Label">ADDITIONAL DOCUMENT</div>
                      <Form.Item
                        name={`multiple_images${index}`}
                        {...formLayout}
                      >
                        <Input
                          type="file"
                          onChange={(event) =>
                            handleFileUpload2({ val: event.target.files[0] })
                          }
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              ))}
              <Col md={{ span: 4, offset: 3 }}>
                <Button className="form-addImage" onClick={handleAddInput}>
                  <HiPlus size={20} />
                  Add Document
                </Button>
              </Col>

              <Col md={{ span: 8, offset: 15 }}>
                <Form.Item>
                  <Button className="applictn-formBttn" htmlType="submit">
                    Submit
                  </Button>
                </Form.Item>
              </Col>
            </Form>
          </div>
        </div>
      ) : // </div>
      null}
      <Footer />
    </>
  );
}

export default ApplicationFirst;
