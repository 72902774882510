const imageSlide =[
    {url:'backAmity.jpg',
    
    },
    {url:'FoodCharity.jpg',
   
    },
    {url:'Charity.jpg',
    
    },
]
export default imageSlide;