import React from "react";
import "./Secondpage.css";
import { MdGroups } from "react-icons/md";
import { useState, useEffect } from "react";
import { BsFillCalendar2HeartFill } from "react-icons/bs";
import { Link } from "react-router-dom";
import StripeCheckout from "react-stripe-checkout";
import { Form, Radio, Space, Input, Button } from "antd";

function Secondpage({ intervalTime }) {
	const [count, setCount] = useState([1, 1, 1]);
	const [value, setValue] = useState(0);
	const [alertMessage, setAlertMessage] = useState(false);
	const [showSecondForm, setShowSecondForm] = useState(false);
	const [showThirdForm, setShowThirdForm] = useState(false);
	const [showFourthForm, setShowFourthForm] = useState(false);
	const [showFifthForm, setShowFifthForm] = useState(false);
	const [showShortTermForm, setShowShortTermForm] = useState(false);
	const [treatmenttype, setTreatmenttype] = useState("short");

	useEffect(() => {
		const interval = setInterval(() => {
			if (count[0] < 3550) {
				setCount([count[0] + 1, count[1], count[2]]);
			} else if (count[1] < 50) {
				setCount([count[0], count[1] + 1, count[2]]);
			} else {
				clearInterval(interval);
			}
		}, intervalTime);
		return () => clearInterval(interval);
	}, [count, intervalTime]);

	const onChange = (e) => {
		console.log("radio checked", e.target.value);
		setValue(e.target.value);
	};
	const FormNextLevel = () => {
		if (value === 0) {
		  setAlertMessage(true);
		} else if (value === 1) {
		  setShowSecondForm(true);
		} else if (value === 2) {
		  setShowThirdForm(true);
		} else if (value === 3) {
			setShowFourthForm(true);
		} else {
			setShowFifthForm(true);
		}
	  };

	return (
		<div className="StartSecondPage">
			<div className="Halfpart">
				<div className="Headers">
					<div className="welcome">WELCOME TO AMITY FORUM</div>
					<div className="Help-div">
						Help Others, help
						<br />
						yourselves
					</div>
				</div>
				<div className="SecondpageBigText">
					Amity India is an organisation which is built on the concept of creating a
					<br />
					reliable platform which will link the needy ones and the donors who can
					help
					<br />
					them. Amity India works as an interface between donors and receivers.Only
					<br />
					deserving cases are put forward in the listings and are catogorized under
					the
					<br />
					projects.
				</div>
				<div className="MembersDonors">
					<div className="Members">
						<div className="groupicon">
							<MdGroups size={30} color={"red"} />
						</div>
						<div style={{ width: "70%", height: "60%", color: "red" }}>
							{count[1]}
							<div style={{ width: "100%", height: "50%", color: "black" }}>
								Amity Members
							</div>
						</div>
					</div>
					<div className="Donors">
						<div className="groupicon">
							<BsFillCalendar2HeartFill size={30} color={"red"} />
						</div>
						<div style={{ width: "70%", height: "60%", color: "red" }}>
							{count[0]}
							<div style={{ width: "100%", height: "50%", color: "black" }}>
								Trust Donors
							</div>
						</div>
					</div>
				</div>
				<div className="ButtonDivSecond">
					<Link to="./DonateNow">
						<button className="DonateButtonSecond1">Donate Now</button>
					</Link>
				</div>
			</div>
			<div className="secondHalf">
				<div className="insideSecond">
					<div
						style={{
							backgroundColor: "#e8e8e8",
							width: "100%",
							height: "100%",
							marginTop: "12px",
							marginBottom: "12px",
							border: "1px solid lightskyblue",
							borderTop: "none",
						}}>
						<div className="formHeading mt-4">AMITY CHARITABLE TRUST FORM</div>
						<div className="formHead">
							{showSecondForm ? (
								<>
									<div>What kind of treatment is needed?</div> <br />
									<Radio.Group
										style={{ width: "100%" }}
										onChange={(e) => setTreatmenttype(e.target.value)}
										value={treatmenttype}>
										<Space direction="vertical" style={{ width: "100%" }}>
											<Radio value={"short"} style={{ marginBottom: 8 }}>
												Short-term treatment
											</Radio>
											<Radio value={"long"} style={{ marginBottom: 8 }}>
												Long-term treatment
											</Radio>
										</Space>
									</Radio.Group>
									<button
										className="backBtnForm mt-4"
										onClick={() => {
											setShowSecondForm(false);
										}}>
										Back
									</button>
									<Button
										className="nextBtnForm mt-4"
										style={{ marginLeft: "10px" }}
										onClick={() => {
											setShowSecondForm(false);
											setShowShortTermForm(true);
										}}>
										Next
									</Button>{" "}
								</>
							) : showShortTermForm ? (
								<>
									{treatmenttype === "short" ? (
										<>
											<div>
												After downloading the below form, fill it and submit it to +91
												8330016570
											</div>
											<div className="mt-4">
												<a
													href="https://drive.google.com/file/d/1yFPtHmvLZ_aG_KROUDn4egspdgeT2bQ_/view"
													target="_blank"
													rel="noopener noreferrer">
													Emergency Medical Support Form
												</a>
											</div>
											<button
												className="backBtnForm mt-4"
												onClick={() => {
													setShowShortTermForm(false);
												}}>
												Back
											</button>
										</>
									) : (
										<>
											<div>
												After downloading the below form, fill it and submit it to +91
												8330016570
											</div>
											<div className="mt-4">
												<a
													href="https://drive.google.com/file/d/1uRMtktN0Ottjz_Fyf8JJlm_bKL_CQv2P/view"
													target="_blank"
													rel="noopener noreferrer">
													Long Term Medical Support Form
												</a>
											</div>
											<button
												className="backBtnForm mt-4"
												onClick={() => {
													setShowShortTermForm(false);
												}}>
												Back
											</button>
										</>
									)}
								</>
							) : showThirdForm ? (
								<>
									<div>
										After downloading the form given below, complete it and submit it to
										the number +91 8330016570
									</div>
									<div className="mt-4">
										<a
											href="https://drive.google.com/file/d/1pRgfjnpRZXPbqJ4niVmTssuhGKhwR6-C/view"
											target="_blank"
											rel="noopener noreferrer">
											Home Project Form
										</a>
									</div>
									<button
										className="backBtnForm mt-4"
										onClick={() => {
											setShowThirdForm(false);
										}}>
										Back
									</button>
									<Button className="nextBtnForm mt-4" style={{ marginLeft: "10px" }}>
										Next
									</Button>{" "}
								</>
							) : showFourthForm ? (
								<>
									<div>
										After downloading the form given below, complete it and submit it to
										the number +91 8330016570
									</div>
									<div className="mt-4">
										<a
											href="https://drive.google.com/file/d/1Ajo6e17wzL1jf2chfwpQO1FKLnXLbGJn/view"
											target="_blank"
											rel="noopener noreferrer">
											Orphan Support Form
										</a>
									</div>
									<button
										className="backBtnForm mt-4"
										onClick={() => {
											setShowFourthForm(false);
										}}>
										Back
									</button>
									<Button className="nextBtnForm mt-4" style={{ marginLeft: "10px" }}>
										Next
									</Button>{" "}
								</>
							) : showFifthForm ? (
								<>
									<div>
										If you do not have the assistance listed above, please contact our
										staff below
									</div>
									<div>+91 8330016570 </div>
									<button
										className="backBtnForm mt-4"
										onClick={() => {
											setShowFifthForm(false);
										}}>
										Back
									</button>
									<Button className="nextBtnForm mt-4" style={{ marginLeft: "10px" }}>
										Next
									</Button>{" "}
								</>
							) : (
								<>
									<div>What kind of help do you need?</div> <br />
									<Radio.Group
										style={{ width: "100%" }}
										onChange={onChange}
										value={value}>
										<Space direction="vertical" style={{ width: "100%" }}>
											<Radio value={1} style={{ marginBottom: 8 }}>
												Health
											</Radio>
											<Radio value={2} style={{ marginBottom: 8 }}>
												House Construction
											</Radio>
											<Radio value={3} style={{ marginBottom: 8 }}>
												Orphan Care
											</Radio>
											<Radio
												value={4}
												style={{
													marginBottom: 8,
													width: "100%",
													display: "flex",
												}}>
												Other
												{value === 4 ? (
													<Input
														style={{
															width: "78%",
															marginLeft: 10,
														}}
													/>
												) : null}
											</Radio>
										</Space>
									</Radio.Group>
									<Button
										onClick={() => {
											FormNextLevel();
										}}
										className="nextBtnForm mt-4">
										Next
									</Button>
									<div style={{ color: "red", fontSize: "12px", marginTop: "5px" }}>
										{alertMessage && "please choose a option"}
									</div>
								</>
							)}
							{}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Secondpage;
